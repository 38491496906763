.splide .splide .splide__arrows {
    .splide__arrow--prev {
        background-color: transparent;
        filter: brightness(0) invert(1);
        svg {
            transition: all 0.2s ease-in-out;
            transform: scale(-1);
            &:hover {
              transform: scale(-1.2);
          }
      }
    }
  .splide__arrow--next {
    background-color: transparent;
    filter: brightness(0) invert(1);
    svg {
      transition: all 0.2s ease-in-out;
      &:hover {
        transform: scale(1.2);
      }
    }
  }
}
