@import "../../Variables.scss";

@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0);
    }
}

.side-nav {
    /* This section calls the slideInFromLeft animation we defined above */
    animation: 0.5s ease-out 0s 1 slideInFromLeft;
    padding: 30px;
    margin-top: 50%;
}

@media only screen and (max-width: 1170px) {
    .side-nav {
        margin-top: 200px;
    }
}



.side-nav .nav-item {
    margin-left: 0.2rem !important;
}

.LeftColumn {
    position: relative;
    width: 200px;
    position: fixed;
    bottom: 0px;
    top: 130px;
    display: block;

    .side-nav .nav-item {
        margin-left: 0.2rem !important;

        a {
            font-family: 'Changa';
            color: rgb(255, 255, 255) !important;
            font-size: 100% !important;
            transition: 0.5s ease;
        }

        a:hover {
            margin-left: 0.2rem !important;
            color: #ffd500 !important;
            transition: 0.5s ease;
        }
    }

    .LeftBottomContent {
        position: absolute;
        bottom: 0;

        .SeoText {
            color: $Grey;
            font-family: $SecondaryFont;
        }
    }
}

@media only screen and (max-width: 1170px) {
    @keyframes slideInFromLeft {
        0% {
            transform: translateX(-100%);
        }

        100% {
            transform: translateX(0);
        }
    }

    .LeftColumn {
        //display: none;
        position: absolute;
        top: 0;
        z-index: 9999;

        .navbar-toggler {
            background-color: rgba(255, 255, 255, 0.2) !important;
            border-radius: .25rem !important;
        }

        .navbar-light .navbar-toggler-icon {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='white' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
            stroke: #491EC4 !important;

        }

        .navbar-nav {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 99;
            background-color: black !important;
        }

        .side-nav .nav-item {

            margin: auto !important;
            width: 50%;
            text-align: center;
        }

        .socialMedia {
            margin: auto !important;
            width: 50%;
            text-align: center;
        }
    }
}
@media only screen and (max-width: 1500px) {
    .nav-square-ad{
        visibility: hidden;
    }
}