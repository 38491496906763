@import "Variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Abel&family=Changa+One:ital@1&display=swap");

@font-face {
  font-family: "changa";
  src: url(fonts/ChangaOne-Italic.ttf);
  font-weight: 100;
}
html {
  scroll-behavior: smooth;
}

body {
  font-family: "Abel";

  h1 {
    font-family: "Changa";
    font-weight: 100;
  }

  h2 {
    font-family: "Changa";
    font-weight: 100;
  }

  h3 {
    font-family: "Changa";
    font-weight: 100;
  }

  h4 {
    font-family: "Changa";
    font-weight: 100;
  }

  h5 {
    font-family: "Changa";
    font-weight: 100;
  }

  p {
    font-family: "Abel";
    font-weight: 100;
  }
}

.terms-text {
  color: #fff !important;
}

label {
  color: #004;
  font-family: "Changa";
  margin: 0 !important;
  font-weight: 100 !important;
}

button {
  padding: 0.5rem 2.5rem;
  color: #004;
  background: #ffd500;
  border-radius: 10px !important;
  border: 0;
  font-family: "Changa" !important;
  font-size: 18px !important;
  transition: 0.3s ease;
  margin: 0.5rem 0 !important;
}

input[type="text"] {
  font-family: Abel;
  color: #004;
  font-size: 18px !important;
}

button:hover {
  color: #ffd500;
  background: #004;
  transition: 0.3s ease;
}

button {
  font-family: "Changa" !important;
  color: #004;
  background: #ffd500;
  border-radius: 15px !important;
  border: 0;
  padding: 0.5rem 2rem;
}

button:hover {
  font-family: "Changa" !important;
  color: #ffd500;
  background: #004;
  transition: 0.5s ease;
}

.AppBackground {
  background: url("./images/background.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.no-overflow {
  width: 800px;
  word-break: break-all;
}

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrap {
  flex: 1;
}

.x-large {
  font-size: 3.8rem;
}

.dark-yellow {
  color: #ffd500;
}

.dark-blue {
  color: #004;
}

.reduce-height {
  line-height: 1;
}

.vertical-line {
  border-right: 0.5px solid rgba(255, 255, 255, 0.5);
}

.large {
  font-size: 1.7rem;
}

.orange {
  color: #ffd500;
}

.red {
  color: #ea3c28;
}

.white {
  color: rgba(235, 235, 235, 1);
}

.gray {
  color: #c1c1c1;
}

.terms-text {
  color: #fff;
}

.DisableOnMobile {
  display: block;
}

@media only screen and (max-width: 1170px) {
  .DisableOnMobile {
    display: none;
  }
}

/*page load fade */

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.page-container {
  margin-top: 120px;
  animation: 2s ease fadeIn;
}
@media only screen and (max-width: 1170px) {
  .page-container {
    margin-top: 60px;
    animation: 2s ease fadeIn;
  }
}
