.TickerBar {
  color: white;
  white-space: nowrap;

  h4 {
    font-size: medium;
    color: #FFD500;
    float: left;
    padding-top: 0.2%;
    font-size: 2rem;
  }

  p {
    display: inline-block;
    font-size: 1.8rem;
  }

  .expert-name-ticker {
    color: #FFD500;
    p {
      padding-right: 5px;
    }
  }
}

@media only screen and (max-width: 1170px) {
  .TickerBar {

    h4 {
      font-size: 1.2rem;
    }

    p {
      font-size: 1.0rem;
    }
  }
}