.expert-link{
    color: rgba(255, 255, 255, 1);
    text-decoration: none;
    font-size: 1.3rem;

    &:hover{
        color: rgba(255, 255, 255, 0.5);
        text-decoration: none;
    }
    &.nav-link.active{
      color: #FFD500 !important;
    }
}
.expert-name{
    color: #FFD500;
    position: relative;
    left: -0.4%;
}
.expert-title{
    color: rgba(255, 255, 255, 0.75);
}
.expert-email{
    color: rgba(255, 255, 255, 0.75);
}
.expert-img{
    height: 700px !important;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 1);
    object-fit: cover;
}
.expert-border{
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.daily-picks{
    color:black !important;
    background-color: white !important;
    border-color: white !important;
}
.daily-picks:hover{
    background-color: #ddd !important;
}
.img-white-filter {
    filter: brightness(0) invert(.7);
    transition: .3s;
}
.img-white-filter:hover {
    filter: brightness(0) invert(1);
}
.social-media {
    width: 30px;
    height: 30px;
}

.twitter{
  max-height: 50vw !important;
  &::-webkit-scrollbar{
    display: none ; 
  }
  height: 280px !important;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 1);
  object-fit: cover;
}
.meet-the-team{
  width: 100%;
}
.social-links a {
  display: inline-block;
}
.social:hover {
  cursor: pointer;
}

/* Modals */
.modal-bg {
position: fixed;
display: flex;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: rgba(0, 0, 0, 0.6);
align-items: center;
justify-content: center;
z-index: 1000;
}
.modal-box {
background-color: #fff;
display: inline-block;
}
.modal-data {
margin: .5em;
width: 700px;
}
.modal-box {
border-radius: 15px;
position: relative
}
.modal-x {
  font-size: 30px;
  position: absolute;
  font-weight: 500;
  color: #fff;
  right: -25px;
  top: -35px;
  cursor: pointer;
}
.hidden {
display: none;
}
.pointer {
  cursor: pointer;
}

/* Picks Modal */

.picks-form-display {
  text-align: center;
}
.picks-form-box {
  width: 800px;
  margin: 1rem;
  justify-content: center;
  align-items: center;
}
.success-msg {
  font-size: 20px;
  font-weight: 600;
}
.form-error-display {
  color: #f00;
}
.modal-data .row input{
  width: 100%
}
.block-label {
  display: block;
}
.terms-link{
  text-decoration: underline;
  color: #000 !important;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"
}
.terms-box .form-check {
  margin: 0 .25rem 0 0 !important;
}
.terms-link:hover{
  color: #022071;
}
.picks-sale-box {
  display: flex
}
.checkoutImage {
  border-radius: 15px 0 0 15px;
  display: block;
  width: 100%;
  height: 540px;
  margin-right: .8rem;
}
.price-info h1,
.price-info h3,
.price-info p {
  margin-bottom: 0;
}
.price-info {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  border: 2px solid #022071;
  border-radius: 15px;
}
.price-info h1 {
  font-size: 60px;
}
.price-info h1,
.price-info h3 {
  color: #022071;
}
.text-right {
  justify-content: right;
}
.inline {
  display: inline-block
}
.picks-form {
  width: 800px;
  margin: 1rem;
}
.picks-form label {
  margin: .2rem 0 0 .2rem;
}
.card-element-border {
  padding: .3rem;
  margin-bottom: .85rem;
}
.card-element-border,
.picks-form input,
.picks-form button {
    padding-left: 1rem;
    padding-right: .8rem;
    border: 0;
    border-bottom: 2px solid #000;
    margin-bottom: 1rem;
}

.picks-form button {
    width: 100%;
    background-color: #fff;
    font-weight: 600;
    padding: .6rem;
    transition: 0.2s ease;
    border: 0;
    background: #FFD500 !important;
}
.picks-form button:hover {
    background-color: #004!important;
    color:#FFD500;
    transition: 0.2s ease;
}
.terms-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: .5rem;
}
input.inline {
  margin-right: .35rem;
}
.form-check {
  cursor: pointer;
}
.pics-btn{
  padding: 0.5rem 2.5rem;
  color: #004;
  background: #FFD500;
  border-radius: 10px !important;
  border: 0;
  font-family: 'Changa One' !important;
  font-size: 18px !important;
  transition: 0.3s ease;
  margin: 0.5rem 0!important;
}
.buy-btn {
    font-family: 'Changa One' !important;
    color: #004;
    background: #FFD500;
    border-radius: 15px !important;
    padding: 0.5rem 2rem;
    transition: 0.3s ease;
}
.buy-btn:hover {
  font-family: 'Changa One' !important;
  color: #FFD500;
  background: #004;
  transition:0.5s ease;
}

.sidebar-box {
  max-height: 500px;
  position: relative;
  overflow: hidden;
}
.sidebar-box .read-more { 
  position: absolute; 
  bottom: 0; 
  left: 0;
  width: 100%; 
  text-align: center; 
  margin: 0; padding: 30px 0; 
	
  /* "transparent" only works here because == rgba(0,0,0,0) */
  background-image: linear-gradient(to bottom, transparent, black);
}
@keyframes read-more-animation{
  from {max-height: 120px;}
  to {max-height: 5000px;

  }
}
@keyframes fading{
  from {opacity: 1;}
  to {opacity: 0;
    visibility: hidden;

  }
}
.max-height-9999{
  animation: read-more-animation ;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  .read-more{
    animation: fading;
    animation-fill-mode: forwards;
    animation-duration: 1s;
  }
}