.ad-image-bigbox {
  // height: 250px !important;
  max-width: 300px;
  height: 250px !important;
}

.ad-image-square {

  max-width: 200px;
  max-height: 200px;
}

.ad-image-skyscraper {
  max-width: 100%;
  max-height: 600px !important;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 1);
}

.ad-image-leaderboard {
  width: 728px;
  height: 90px;
  background: grey;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 1);
  margin-bottom: 15px;
}

@media only screen and (max-width: 1330px) {

  .ad-image-leaderboard {
    display: none;
  }

}

.BackgroundBlur {
  width: 100%;
  height: 80px;
  background: black;
  filter: blur(2px);
  position: absolute;
}

.ad-image-affiliateLogo {
  height: 80px;
  margin-bottom: 5px;

  img {
    padding-left: 5px;
    padding-right: 5px;
    width: 100%;
    height: auto;
    max-width: 170px;
    max-height: 100px;
  }

  .AffiliateHorizontal {
    height: 2px;
    width: 100%;
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    text-decoration: none;
  }

  .my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }


  p {
    color: white;
    padding: 0;
    text-align: center;
    padding-right: 3rem;
  }

  .AffiliateButton {
    background: #f5c71a;
    max-height: 2.5rem;
    text-align: center;
    border: none;
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 1);
    display: block;
    overflow: auto;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-decoration: none;
    font-size: 16px !important;

  }

  @media only screen and (max-width: 1170px) {
    .AffiliateButton {
      font-size: 12px !important;
    }
  }

  .AffiliateButton:hover {
    background: #004;
    color: #f5c71a;
    cursor: pointer;
    transition: 0.5s ease;
    text-decoration: none;
  }
}

.no-decoration {
  text-decoration: none !important;
}

.no-decoration:hover {
  text-decoration: none !important;
}