@import "../../App.scss";
.cu {
  font-size: 40pt !important;
  color: $white;
}


.form-submit {
    font-family: 'Changa One';
    font-size: 22px;
    color: #004 !important;
    background: #ffd800;
    padding: 0.5rem 2rem;
    border-radius: 15px;
    border: 0;
    transition: 0.5s ease;
}

.form-submit:hover {
    color: #ffd800 !important;
    background: #004;
    transition:0.5s ease;
}

.cf input[type="text"],
select.form-control {
  background: transparent;
  border: none;
  color: white !important;
  border-bottom: 1px solid $white;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
  font-size: 16px;
  font-family:'Abel';
}

.cf input[type="text"]:focus,
select.form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-bottom: 2px solid #0080ff;
  background: transparent;
  color: white;
}
/*placeholder css*/
.cf input::-webkit-input-placeholder {
  color: rgb(214, 214, 214) !important;
  font-weight: 600;
}

.cf input:-moz-placeholder {
  /* Firefox 18- */
  color: rgb(214, 214, 214) !important;
  font-weight: 600;
}

.cf input::-moz-placeholder {
  /* Firefox 19+ */
  color: rgb(214, 214, 214) !important;
  font-weight: 600;
}

.cf input:-ms-input-placeholder {
  color: rgb(214, 214, 214) !important;
  font-weight: 600;
}

.address{
    margin:0;
}