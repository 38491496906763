@import "../../Variables.scss";

.footer {
  color: $white;
  background: rgb(204, 204, 204);
  padding-top: 2em;
  position: relative;
  bottom: 0;
  width: 100%;
  height: $FooterHeight;
}

.social-media {
  width: 30px;
  height: 30px;
}

.bg-gray {
  background-color: #dfdfdf;
  height: 100%;
}

.black {
  color: black;
}

.img-gray-filter {
  filter: grayscale(100%);
  opacity: 0.6;
}

.small-font {
  font-size: 0.8rem;
}

.x-small {
  font-size: 0.5rem;
}

.medium-font {
  font-size: 1rem;
}

.bold {
  font-weight: bold;
}

.subscribe {
  position: relative;
  top: -10%;
  border-radius: 5px;
  border-style: solid;
  border-color: $Orange;
  width: 275px;
  background-color: darkgray;
}


.footer-nav-link {
  color: #004 !important;

  &:hover {
    text-decoration: none;
    color: black;
  }
}

.gradiant {
  background-image: linear-gradient(to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 1));
  position: absolute;
  bottom: $FooterHeight;
  left: 0px;
  height: 10vh;
  width: 100%;
}

.SocialMediaIcons {
  .icon {
    transition: 0.5s ease;
  }

  .icon:hover {
    transform: translate(0, -5px);
    color: #ffd500 !important;
    transition: 0.5s ease;
  }

}