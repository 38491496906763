@import "../../Variables.scss";
.Post {
  max-height: 220px;
  // max-width: 340px;
  background: linear-gradient(
    to bottom,
    rgba(235, 224, 224, 0.123),
    rgb(0, 0, 0)
  );
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 1);
  position: relative;
  overflow: hidden;
  .gradiant {
    height: 100%;
    width: 100%;
    position: absolute;
    background: linear-gradient(
      to bottom,
      rgba(235, 224, 224, 0.123),
      rgb(0, 0, 0)
    );
    z-index: 1;
  }
  .Post-Text {
    position: absolute;
    z-index: 2;
    bottom: 10px;
    left: 20px;
    h1 {
      font-size: medium;
      color: $Orange;
    }

    h2 {
      font-size: small;
      color: white;
    }

    h3 {
      font-size: x-small;
      color: $Grey;
    }
  }
  img {
    height: 100%;
    width: 100%; /* width of container */
    height: 200px; /* height of container */
    object-fit: cover;
    /*  position: relative;
    z-index: 0;*/
    max-width: 100%;
    -webkit-transition: all 0.3s ease; /* Safari and Chrome */
    -moz-transition: all 0.3s ease; /* Firefox */
    -ms-transition: all 0.3s ease; /* IE 9 */
    -o-transition: all 0.3s ease; /* Opera */
    transition: all 0.3s ease;
  }
}

.Post .gradiant:hover ~ img {
  -webkit-transform: scale(1.1); /* Safari and Chrome */
  -moz-transform: scale(1.1); /* Firefox */
  -ms-transform: scale(1.1); /* IE 9 */
  -o-transform: scale(1.1); /* Opera */
  transform: scale(1.1);
}
